import { COOKIE_STORAGE, LOCAL_STORAGE, PAYMENT_TERM } from '../../../../React/code/Utilities/ExtranetConstants';
import Cookies from 'js-cookie';
import { DATE_FILTERS } from '../../../../../Feature/DmtUser/code/Views/Feature/DmtUser/MyAccount/Utils/OrderHistoryConstants';
import { setTotalCartQuantity } from '../../../../../Feature/Navigation/code/Views/Feature/Navigation/MainNavigation/actions/TopNavigationActions';
import { store } from '../../../../../Feature/Navigation/code/Views/Feature/Navigation/MainNavigation/MainNavigation';

const configForAddToCartBtn = (erpOutdoor, productType, date, priceListId, orderType) => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXTRANET_USER));
  if (user) {
    const retailer = user.CurrentSelectedRetailer;
    if (retailer && retailer.B2BEnable) {
      const country = retailer.Country.Abbrevation;
      const isAmericas = country === 'US' || country === 'CA';
      const customerNumber = retailer.ErpId;
      const facility = retailer.Facility;
      const state = checkIfDropship() ? retailer.Dropship.State : retailer.Address.State;
      const lang = Cookies.get(COOKIE_STORAGE.LANGUAGE);
      const url = isAmericas ? '/api/sitecore/itemdata/itemdataamericas' : '/api/sitecore/itemdata/itemdataerow';

      const quantity = 1;

      const body = isAmericas
        ? JSON.stringify({
            CustomerNumber: customerNumber,
            ItemNumber: erpOutdoor,
            Facility: facility,
            Quantity: quantity,
            Language: lang,
            Date: date,
            StateOrProvince: state,
            ProductType: productType,
            PriceListId: priceListId,
            OrderType: orderType,
          })
        : JSON.stringify({
            CustomerNumber: customerNumber,
            ItemNumber: erpOutdoor,
            Facility: facility,
            Quantity: quantity,
            Language: lang,
            Date: date,
          });
      return { body, url };
    }
  }
  return undefined;
};

const configForAddToCartBtnInBatches = (items, productType, date, priceListId, orderType) => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXTRANET_USER));
  if (user) {
    const retailer = user.CurrentSelectedRetailer;
    if (retailer) {
      const country = retailer.Country.Abbrevation;
      const isAmericas = country === 'US' || country === 'CA';
      const customerNumber = retailer.ErpId;
      const facility = retailer.Facility;
      const state = checkIfDropship() ? retailer.Dropship.State : retailer.Address.State;
      const lang = Cookies.get(COOKIE_STORAGE.LANGUAGE);
      const url = isAmericas
        ? '/api/sitecore/itemdata/itemdatabatchamericas'
        : '/api/sitecore/itemdata/itemdatabatcherow';

      const quantity = 1;

      const body = isAmericas
        ? JSON.stringify({
            CustomerNumber: customerNumber,
            Items: items,
            Facility: facility,
            Quantity: quantity,
            Language: lang,
            Date: date,
            StateOrProvince: state,
            ProductType: productType,
            PriceListId: priceListId,
            OrderType: orderType,
          })
        : JSON.stringify({
            CustomerNumber: customerNumber,
            Items: items,
            Facility: facility,
            Quantity: quantity,
            Language: lang,
            Date: date,
          });
      return { body, url };
    }
  }
  return undefined;
};

export function fetchDataForAddToCartButtonInBatches(
  items,
  onSuccess,
  onFailure,
  productType = '',
  date,
  abortSignal,
  priceListId,
  orderType,
  orderId
) {
  const addToCartBtnData = configForAddToCartBtnInBatches(items, productType, date, priceListId, orderType);
  if (addToCartBtnData) {
    return fetch(addToCartBtnData.url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: addToCartBtnData.body,
      signal: abortSignal,
    })
      .then((response) => response.json())
      .then((data) => {
        onSuccess(data, items, orderId);
      })
      .catch((error) => {
        onFailure(error);
      });
  }
}

export function fetchDataForAddToCartButton(
  erpOutdoor,
  onSuccess,
  onFailure,
  productType = '',
  date,
  abortSignal,
  priceListId,
  orderType
) {
  const addToCartBtnData = configForAddToCartBtn(erpOutdoor, productType, date, priceListId, orderType);
  if (addToCartBtnData) {
    return fetch(addToCartBtnData.url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: addToCartBtnData.body,
      signal: abortSignal,
    })
      .then((response) => response.json())
      .then((data) => {
        onSuccess(data, erpOutdoor);
      })
      .catch((error) => {
        onFailure(error);
      });
  }
}

export function SolutionAddToCartBtn(erpOutdoor, abortSignal) {
  const addToCartBtnData = configForAddToCartBtn(erpOutdoor);
  if (addToCartBtnData) {
    return fetch(addToCartBtnData.url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: addToCartBtnData.body,
      signal: abortSignal,
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  }
}

export function CreatedOrder(
  onSuccess,
  onFailure,
  orderType,
  cartLines,
  reference,
  isFitKit,
  requestedDeliveryDate = '',
  freightCost,
  freightMethod,
  paymentTransactionId,
  yourRef,
  customDeliveryTerm,
  warehouse,
  isCustomerOrderRegistrationOrder
) {
  let language = Cookies.get(COOKIE_STORAGE.LANGUAGE);
  let user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXTRANET_USER));
  let currentSelectedRetailer = user.CurrentSelectedRetailer;
  let address = currentSelectedRetailer.Address;
  let dropShip = currentSelectedRetailer.Dropship;

  //Set default values if not Customer order registration order for orderType.
  if (!isCustomerOrderRegistrationOrder) {
    if (!orderType || !orderType.trim()) orderType = currentSelectedRetailer.OrderType;
  }
  if (!reference) reference = new Date().getTime();
  if (!warehouse || !warehouse.trim()) warehouse = currentSelectedRetailer.Warehouse;

  let body = {
    ErpId: currentSelectedRetailer.ErpId,
    ReferenceNumber: reference,
    OrderType: orderType,
    IsFitKit: isFitKit,
    DeliveryAddressId: address?.AddressM3Id,
    PaymentTerm: currentSelectedRetailer.PaymentTerm,
    DropshipAddress: dropShip,
    Facility: currentSelectedRetailer.Facility,
    Warehouse: warehouse,
    CartLines: cartLines,
    RequestedDeliveryDate: requestedDeliveryDate,
    FreightCost: freightCost,
    FreightMethod: freightMethod,
    PaymentTransactionId: paymentTransactionId, //empty string unless it's a Cenpos order. Then use Cenpos transaction id
    DeliveryTerm: currentSelectedRetailer.DeliveryTerm,
    CustomDeliveryTerm: customDeliveryTerm,
    Language: language,
    YourRef: yourRef,
    isCustomerOrderRegistrationOrder: isCustomerOrderRegistrationOrder,
  };

  fetch(`/api/sitecore/order/createorder`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => onSuccess(data, reference))
    .catch((error) => onFailure(error));
}

export function getCart(onSuccess, onFailure) {
  const cartIdentifier = localStorage.getItem(LOCAL_STORAGE.CART_IDENTIFIER);

  fetch('/api/sitecore/standalonecart/getcart?cartIdentifier=' + cartIdentifier, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
    });
}

export const getTotalCartQuantity = () => {
  const cartIdentifier = localStorage.getItem(LOCAL_STORAGE.CART_IDENTIFIER);
  fetch('/api/sitecore/standalonecart/GetCartCount?cartIdentifier=' + cartIdentifier, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      store.dispatch(setTotalCartQuantity(data));

      document.querySelectorAll('.cart-counter').forEach((currentNode) => {
        currentNode.innerHTML = data;
        currentNode.classList.toggle('d-none', data.TotalQuantity === 0);
      });
    })
    .catch((error) => {
      console.error('Error: ', error);
    });
};

const gtmEvent = (event) => {
  window.google = window.google || {};
  dataLayer.push(event);
};

export function addCartLines(productList, onSuccess, onFailure, gmtEventData = null) {
  fetch('/api/sitecore/standalonecart/addcartlines', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cartLines: productList,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (gmtEventData) {
        gtmEvent(gmtEventData);
      }
      getTotalCartQuantity();
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
    });
}

export function removeCartLines(cartId, productId, quantity, onSuccess, onFailure, gmtEventData) {
  fetch('/api/sitecore/standalonecart/removecartlines', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cartLines: [
        {
          cartId: cartId,
          productId: productId,
          quantity: quantity,
        },
      ],
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (gmtEventData) {
        gtmEvent(gmtEventData);
      }
      getTotalCartQuantity();
      onSuccess(data);
    })
    .catch((error) => {
      onFailure(error);
    });
}

export function getFormattedPrice(price, onSuccess, abortSignal, onFail) {
  const language = Cookies.get(COOKIE_STORAGE.LANGUAGE);
  fetch(`/api/sitecore/retailers/getformattedprice?price=${price}&language=${language}`, {
    method: 'GET',
    signal: abortSignal,
  })
    .then((result) => {
      if (result.ok) return result.json();
    })
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.log(error);
      if (onFail) onFail();
    });
}

export function getFormattedPriceWithCurrencySymbol(price) {
  if (!price && price !== 0) return '';
  const language = Cookies.get(COOKIE_STORAGE.LANGUAGE);
  const currencyCode = _THULEDATA?.currencyCode;
  if (!language || !currencyCode) return '';
  return price.toLocaleString(language, { style: 'currency', currency: currencyCode, maximumFractionDigits: 2 });
}
export function getFormattedPrices(prices, onSuccess, abortSignal, onFail) {
  const language = Cookies.get(COOKIE_STORAGE.LANGUAGE);
  fetch(`/api/sitecore/retailers/getformattedprices`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ prices: prices, language: language }),
    signal: abortSignal,
  })
    .then((result) => {
      if (result.ok) return result.json();
    })
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.log(error);
      if (onFail) onFail();
    });
}

export function getRetailer() {
  let retailer = null;
  const user = getUser();
  if (user) {
    retailer = user.CurrentSelectedRetailer;
  }
  return retailer;
}

export function getUser() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXTRANET_USER));
}

export function getCurrentLanguage() {
  return Cookies.get(COOKIE_STORAGE.LANGUAGE);
}

export function checkIfAmericas() {
  let isAmericas = false;
  const retailer = getRetailer();
  if (retailer) {
    const country = retailer.Country.Abbrevation;
    isAmericas = country === 'US' || country === 'CA';
  }
  return isAmericas;
}

export function checkIfDropship() {
  let isDropship = false;
  const retailer = getRetailer();
  if (retailer) {
    if (retailer.Dropship) isDropship = true;
  }
  return isDropship;
}

export function GetPaymentTermFromPaymentTermId(paymentTermId) {
  // Empty or null = Invoice
  if (!paymentTermId) return PAYMENT_TERM.INVOICE;

  // PaymentTerm , i.e 030 etc.. . = Invoice
  if (paymentTermId !== 'CC' && paymentTermId !== '000') return PAYMENT_TERM.INVOICE;

  // PaymentTerm equals CC = Credit card.
  if (paymentTermId === 'CC' || paymentTermId === '000') return PAYMENT_TERM.CREDIT_CARD;
}

//expects dateformat in string as YYYYMMMDD (20240110)
export const formatDateForSorting = (date) => {
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);

  return new Date(year, month - 1, day);
};

//Used to get a date in the past based on
//date filter the user has selected
//E.g. see orders starting from a certain date
export const getDateFrom = (clickedFilter) => {
  const dateTo = new Date();
  const year = dateTo.getFullYear();
  const month = dateTo.getMonth();
  const day = dateTo.getDate();

  let dateFrom = new Date();
  if (clickedFilter === DATE_FILTERS.ONE_YEAR) {
    dateFrom = new Date(year - 1, month, day);
  } else if (clickedFilter === DATE_FILTERS.THREE_MONTHS) {
    dateFrom = new Date(year, month - 3, day);
  } else {
    dateFrom = new Date(year, month - 1, day);
  }

  return dateFrom;
};

export const downloadInvoice = (invoiceNumber, onSuccess, onFail, abortSignal) => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXTRANET_USER));
  const retailer = user?.CurrentSelectedRetailer;
  const facility = retailer?.Facility;
  fetch('/api/sitecore/invoice/downloadinvoice?invoiceNumber=' + invoiceNumber + '&facility=' + facility, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    signal: abortSignal,
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      onSuccess(blob);
    })
    .catch((error) => {
      onFail(error);
    });
};

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  //set the filename
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const openTutorialModal = () => {
  const background = document.querySelector('#tutorial-background');
  const component = document.querySelector('#tutorial-component');

  background.classList.add('d-block');
  component.classList.add('d-flex');

  background.classList.remove('d-none');
  component.classList.remove('d-none');
};

export const formatProductId = (product, showEan) => {
  if (!product.ErpOutdoor) return product.ProductId;
  else if (showEan) return `${product.ErpOutdoor} / ${product.EAN ? product.EAN : ''}`;
  else if (product.ErpOutdoor === product.ThuleSku || !product.ThuleSku) return product.ErpOutdoor;
  else return `${product.ErpOutdoor} / ${product.ThuleSku}`;
};

export const getM3FormattedDate = (date) => {
  if (!date) return '';
  const dateMatch = date.match(/\d+/);
  if (dateMatch) {
    let newDate = new Date(+dateMatch[0]);
    newDate.setHours(12);
    return newDate.toISOString().slice(0, 10).replaceAll('-', '');
  }
  return '';
};
