import React, { useEffect, useState } from 'react';
import { store } from '../../../../../Feature/Navigation/code/Views/Feature/Navigation/MainNavigation/MainNavigation';
import { toggleCartNotification } from '../../../../../Feature/Navigation/code/Views/Feature/Navigation/MainNavigation/actions/TopNavigationActions';
import { addCartLines, fetchDataForAddToCartButton } from '../../../../Common/code/Utils/Extranet/Utils';
import { LOCAL_STORAGE, PRODUCT_STATUS, ROLE_GUIDS } from '../../Utilities/ExtranetConstants';
import Spinner from './Spinner';

const AddToCartButtonReact = ({ className, productId, productData, productType }) => {
  const [user, setUser] = useState({});
  const [allowedToSeePrices, setAllowedToSeePrices] = useState(true);
  const [canBuyProducts, setCanBuyProducts] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showAddToCartLoader, setShowAddToCartLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [amount, setAmount] = useState(1);
  const [showPriceSpinner, setShowPriceSpinner] = useState(true);
  const [showInStockSpinner, setShowInStockSpinner] = useState(true);
  const [m3ResponseData, setM3ResponseData] = useState({});
  const abortController = new AbortController();
  const [showPrices, setShowPrices] = useState(localStorage.getItem('showPrices') === 'true');
  const showM3Date = productData.StatusErp !== 'Pending' && productData.StatusErp !== 'Launch ready';
  const rrpText = window._THULEDATA._MainNavigation.RRPText;
  const deliveryLabel = window._THULEDATA.TopNavigation?.Translations.DeliveryLabel;
  const launchDateText = window._THULEDATA.TopNavigation?.Translations.LaunchDateText;
  const generalErrorText = window._THULEDATA.TopNavigation?.Translations.GeneralErrorText;
  const addToCartText = window._THULEDATA.TopNavigation?.Translations.AddToCartButtonText;
  const discontinuedText = window._THULEDATA.TopNavigation?.Translations.DiscontinuedText;

  useEffect(() => {
    window.addEventListener('toggleShowPrices', (e) => handleTogglePrices(e.detail));
    return () => {
      window.removeEventListener('toggleShowPrices', handleTogglePrices);
    };
  }, []);

  const handleTogglePrices = (show) => {
    setShowPrices(show);
  };

  useEffect(() => {
    if (productData.ProductType === 'Spare part') {
      productType = 'Spare part';
    }
    if (productData.StatusErp !== PRODUCT_STATUS.DISCONTINUED) {
      fetchDataForAddToCartButton(productId, onSuccess, onFailure, productType, '', abortController.signal);
      setUser(JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXTRANET_USER)));
      return () => abortController.abort();
    } else {
      setErrorMessage(discontinuedText);
      setShowSpinner(false);
      setShowInStockSpinner(false);
      setShowPriceSpinner(false);
    }
  }, []);

  function onSuccess(data) {
    if (
      data.Error ||
      data.TranslatedErrorMessage ||
      !data.HasSalesPrice ||
      (showM3Date && !data.DeliveryDate) ||
      (!showM3Date && !productData.LaunchDate)
    ) {
      setErrorMessage(data.TranslatedErrorMessage ?? generalErrorText);
      setShowSpinner(false);
      setShowInStockSpinner(false);
      setShowPriceSpinner(false);
    } else {
      productData.m3Data = data;
      setM3ResponseData(data);
      setShowSpinner(false);
      setShowInStockSpinner(false);
      setShowPriceSpinner(false);
    }
    setShowSpinner(false);
  }

  useEffect(() => {
    const isDealerHidePrices = user.HighestRole?.Id === ROLE_GUIDS.DEALER_HIDE_PRICES;
    if (isDealerHidePrices) {
      setAllowedToSeePrices(false);
      setCanBuyProducts(false);
    }

    const isCustomerOrderRegistrationUser = user.HighestRole?.Id === ROLE_GUIDS.CUSTOMER_ORDER_REGISTRATION_USER;
    if (isCustomerOrderRegistrationUser) {
      setCanBuyProducts(false);
    }
  }, [user]);

  function onFailure(error) {
    console.error('Error:', error);
  }

  function addProductToCart(e) {
    e.preventDefault();
    if (!canBuyProducts) return;

    setShowAddToCartLoader(true);

    const cartId = localStorage.getItem(LOCAL_STORAGE.CART_IDENTIFIER);

    let product = [
      {
        cartId: cartId,
        productId: productData.ProductId,
        quantity: amount,
      },
    ];

    addCartLines(product, onSuccess, onFailure);

    function onSuccess() {
      store.dispatch(toggleCartNotification(true, amount, productData.Name));
      window.dispatchEvent(new CustomEvent('triggerCartReload', { detail: Date.now() }));
      setShowAddToCartLoader(false);
    }

    function onFailure(error) {
      console.error('Error: ', error);
      setShowAddToCartLoader(false);
    }
  }

  function onBlurInput(qty) {
    if (isNaN(qty) || amount < 1) setAmount(1);
    else setAmount(Number.parseInt(qty));
  }

  return (
    <div className="add-to-cart-button-react d-flex">
      <div className="quantity-price-container d-flex flex-column justify-content-center">
        <div className="d-flex">
          {!errorMessage && (
            <div className="quantity-container border align-self-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAmount(amount - 1);
                }}
                disabled={amount === 1}
              >
                -
              </button>
              <label className="sr-only" htmlFor={`quicksearch-product-qty-${productData.ErpOutdoor}`}>
                Quantity
              </label>
              <input
                type="text"
                value={amount}
                onClick={(e) => e.preventDefault()}
                onChange={(e) => {
                  e.preventDefault();
                  setAmount(e.currentTarget.value);
                }}
                onBlur={(e) => onBlurInput(e.currentTarget.value)}
                id={`quicksearch-product-qty-${productData.ErpOutdoor}`}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAmount(amount + 1);
                }}
              >
                +
              </button>
            </div>
          )}
          {allowedToSeePrices &&
            showPrices &&
            (m3ResponseData.SalesPrice || productData.PriceContainer.PriceFormatted) && (
              <div className="price-container">
                {showPriceSpinner ? <Spinner /> : <p className="price">{m3ResponseData.SalesPrice}</p>}
                <p className="rrp">{`${rrpText} ${productData.PriceContainer.PriceFormatted}`}</p>
              </div>
            )}
        </div>
      </div>
      <div className="btn-container d-flex flex-column justify-content-center">
        <div className="button-container">
          {showSpinner ? (
            <Spinner extraClasses="big-spinner" />
          ) : (
            <>
              {errorMessage ? (
                <p className="error-message">{errorMessage}</p>
              ) : (
                <>
                  <div className="add-to-your-cart">
                    {canBuyProducts && (
                      <button onClick={(e) => addProductToCart(e)} className={className}>
                        {addToCartText}
                        {showAddToCartLoader && <div className="add-to-cart-loader"></div>}
                      </button>
                    )}
                  </div>
                  {showM3Date && (
                    <p className="stock-status">
                      {showInStockSpinner ? (
                        <Spinner />
                      ) : (
                        <>
                          {m3ResponseData.IsAvailable && <i className="fas fa-check checkmark"></i>}
                          {m3ResponseData.Availability}
                        </>
                      )}
                    </p>
                  )}
                  <p>
                    {showM3Date
                      ? `${deliveryLabel} ${m3ResponseData.DeliveryDate}`
                      : `${launchDateText} ${productData.LaunchDate}`}
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddToCartButtonReact;
